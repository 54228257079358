<template>
    <div>
        <v-card align="left" justify="left" class="ma-3">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2"> message </v-icon
                >FAQ > Dúvidas frequentes
            </v-card-text>
        </v-card>
        <v-card class="ma-3 pa-3" color="primary">
            <v-row class="py-8">
                <v-col cols="3" xs="3" md="3" class="ma-3 pa-3">
                    <v-icon
                        class="white--text ma-3 pa-3 mx-auto my-auto"
                        size="100"
                        elevation-8
                    >
                        quiz
                    </v-icon>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="5"
                    class="ma-3 pa-3 mx-auto my-auto"
                    align="left"
                    justify="left"
                >
                    <v-card-text
                        class="white--text font-weight-black display-2"
                    >
                        FAQ | Dúvidas Frequentes
                    </v-card-text>
                </v-col>
                <v-col
                    sm="12"
                    md="2"
                    class="ma-3 pa-3 mx-auto my-auto"
                    align="left"
                    justify="left"
                >
                    <v-card-text
                        class="white--text font-weight-light subtitle mb-3 pa-3"
                    >
                        Nessa página você poderá encontrar a maior parte das
                        dúvidas frequentes de uso do painel Recuperi.
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>

        <template>
            <v-card class="ma-3 pa-3">
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Digite aqui sua pesquisa por palavras chave, exemplo: Protesto"
                        single-line
                        solo
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    :headers="headersTb1"
                    :items="dessertsTb1"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    show-expand
                    :footer-props="{
                        itemsPerPageOptions: [10, 20, 100, -1],
                        itemsPerPageText: 'Itens por página:'
                    }"
                    loading-text="Pesquisando resultados..."
                    no-results-text="Nenhum resultado encontrado..."
                    item-key="name"
                    :search="search"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title
                                >Dúvidas Frequentes</v-toolbar-title
                            >
                        </v-toolbar>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-6">
                            {{ item.description }}
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </template>
        <v-card-text class="text-center"
            >Tem duvidas que não foram encontradas aqui nesse painel? Entre em
            contato com nossa equipe de suporte, teremos o maior prazer em
            ajudar.
        </v-card-text>
    </div>
</template>

<script>
export default {
    data() {
        return {
            expanded: [],
            singleExpand: true,
            search: '',
            headersTb1: [
                {
                    text: 'Informações sobre Protesto Eletrônico',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                }
            ],
            dessertsTb1: [
                {
                    name: '1. O que é um Protesto?',
                    description:
                        'Segundo a norma contida no art. 1º da Lei 9.492/97, "Protesto é o ato formal e solene pelo qual se prova a inadimplência e o descumprimento de obrigação originada em títulos (Cheque, Duplicata Mercantil, Nota Promissória, etc) em outras palavras, o protesto é uma forma de comunicar uma dívida publicamente, com finalidade de que o inadimplente possa primeiramente pagar a dívida antes de realizar novas solicitações.'
                },
                {
                    name: '2. O que é um protesto eletrônico?',
                    description:
                        'De modo a agilizar ainda mais os processos dos Cartórios de Protesto, disponibilizamos a funcionalidade de Solicitação de Protesto Eletrônico. Essa funcionalidade permite aos usuários realizarem solicitações de protesto diretamente pelo sistema Recuperi, atribuindo os pedidos aos cartórios competentes.'
                },
                {
                    name: '3. O que posso executar através da Recuperi?',
                    description:
                        'A Recuperi disponibiliza a plataforma para os usuários/apresentantes/credores de dívidas poderem efetuar envio de títulos em massa para protesto em todo o Brasil eletronicamente, onde também pode ser enviado direto aos cartórios o cancelamento ou a anuência também eletrônicas, dispensando os documentos físicos.'
                },
                {
                    name:
                        '4. Qual a quantidade de títulos que posso protestar através da Recuperi?',
                    description:
                        'Nossa abrangência de títulos é ilimitada: ou seja, o usuário pode subir a quantidade desejada de títulos independente da quantidade e/ou volume de dados, processamos todos os títulos diariamente e encaminhamos de forma automática ao sistema central de cartórios nacional. Limita-se a 2000 títulos em cada arquivo de upload.'
                },
                {
                    name:
                        '5. O que acontece se o título estiver com erro de preenchimento?',
                    description:
                        'Ao fazer o upload de um título no sistema de forma incorreta, ou seja, com dados inconsistentes nosso sistema fará uma primeira validação durante o processo de upload e informará quais pendências existem para determinados títulos. Após essa validação o usuário poderá realizar a correção dos dados para fazer um novo upload, ou dependendo da necessidade terá que fazer um novo upload.'
                },
                {
                    name: '6. Posso protestar devedores com CPF e CNPJ?',
                    description:
                        'Sim, nosso sistema processa dados de devedores independente do tipo fiscal: podendo ser pessoa física, ou pessoa jurídica! Consulte nosso comercial para esclarecer de forma detalhada todos os processos.'
                },
                {
                    name: '7. O que é uma Anuência?',
                    description:
                        'A anuência (autorização de cancelamento) é um documento de confirmação emitido por um credor (a pessoa que deve receber a dívida) em benefício de um devedor (a pessoa que está devendo), afirmando que a dívida foi quitada com o credor. Este documento autoriza o cartório cancelar o protesto, mediante do pagamento das custas cartorárias ao cartório de protesto pelo devedor. Pela Recuperi, este documento é enviado eletronicamente.'
                },
                {
                    name: '8. O que é um Cancelamento?',
                    description:
                        'O cancelamento também é enviado ao cartório para que eles sejam autorizados a cancelar o título, porém de forma imediata cobrando as custas direto do apresentante.'
                },
                {
                    name:
                        '9. O que fazer quando o cartório não encontra a anuência eletrônica?',
                    description:
                        'É importante frisar ao devedor que ele deve avisar no cartório que a anuência foi enviada eletronicamente. Se o devedor for do estado de SP, a mesma estará no sistema Cenprot, caso o devedor for dos outros estados a anuência estará no sistema C.R.A.'
                },
                {
                    name:
                        '10. O que fazer se o título retornar do cartório com status Irregular-Sem Custas?',
                    description:
                        'O título pode ser reenviado sem problema algum, porém é importante verificar o motivo nos Detalhes do Título e corrigir, exemplo: Endereço Incorreto.'
                }
            ]
        };
    }
};
</script>
